import { graphql } from "gatsby"
import React from "react"

import FeatureBannerComponent from "./FeatureBanner"

export const fragment = graphql`
  fragment FeatureBannerFragment on WpPage_Flexlayouts_FlexibleLayouts_FeatureBanner {
    backgroundImage {
      altText
      sourceUrl
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            width: 1860
            height: 530
            quality: 75
            placeholder: NONE
          )
        }
      }
    }
    foregroundImg {
        desktop {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 1908
                placeholder: NONE
              )
            }
          }
        }
        mobile {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                width: 979
                placeholder: NONE
              )
            }
          }
        }
      }
    title
    subTitle
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFFeatureBanner = ({
  backgroundImage,
  foregroundImg,
  title,
  subTitle,
  config
}) => (
  <FeatureBannerComponent
    backgroundImage={backgroundImage}
    foregroundImg={foregroundImg}
    title={title}
    subTitle={subTitle}
    config={config}
  />
)
