import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

import { Section } from "../../UI/Common"

import {
  grandForeImgWrapperCls,
  bkgdImgWrapperCls,
  foreImgWrapperCls,
  titleCls,
  subTitleCls,
  bgImageCls,
  foreImageCls,
} from "./FeatureBanner.module.scss"

const FeatureBanner = ({
  backgroundImage,
  foregroundImg,
  title,
  subTitle,
  config,
}) => {
  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <div className="position-relative">
        <div className={`padded-component ${bkgdImgWrapperCls}`}>
          {backgroundImage && (
            <GatsbyImage
              className={bgImageCls}
              alt={backgroundImage.altText}
              image={getImage(backgroundImage.localFile)}
            />
          )}
        </div>
        <div className={grandForeImgWrapperCls}>
          <div className={foreImgWrapperCls}>
              <div className={titleCls}>{title}</div>
              {foregroundImg && (
                <>
                  <GatsbyImage
                    className={`d-lg-none ${foreImageCls}`}
                    style={{ position: "absolute" }}
                    alt={foregroundImg.mobile.altText}
                    image={getImage(foregroundImg.mobile.localFile)}
                  />
                  <GatsbyImage
                    className={`d-none d-lg-block ${foreImageCls}`}
                    style={{ position: "absolute" }}
                    alt={foregroundImg.desktop.altText}
                    image={getImage(foregroundImg.desktop.localFile)}
                  />
                </>
              )}
              <div className={subTitleCls}>{subTitle}</div>
            </div>
        </div>
      </div>
    </Section>
  )
}

export default FeatureBanner
