// extracted by mini-css-extract-plugin
export var bgGrey100 = "FeatureBanner-module--bg-grey-100--60dfc";
export var bgGrey150 = "FeatureBanner-module--bg-grey-150--8cb0f";
export var bgGrey200 = "FeatureBanner-module--bg-grey-200--63b96";
export var bgGrey300 = "FeatureBanner-module--bg-grey-300--10a66";
export var bgGrey400 = "FeatureBanner-module--bg-grey-400--44d3a";
export var bgGrey500 = "FeatureBanner-module--bg-grey-500--7ee2a";
export var bgGrey600 = "FeatureBanner-module--bg-grey-600--c5296";
export var bgGrey700 = "FeatureBanner-module--bg-grey-700--2e3ba";
export var bgGrey800 = "FeatureBanner-module--bg-grey-800--34ca7";
export var bgGrey900 = "FeatureBanner-module--bg-grey-900--f0aae";
export var bgImageCls = "FeatureBanner-module--bgImageCls--1fea5";
export var bkgdImgWrapperCls = "FeatureBanner-module--bkgdImgWrapperCls--5269a";
export var foreImageCls = "FeatureBanner-module--foreImageCls--14baf";
export var foreImgWrapperCls = "FeatureBanner-module--foreImgWrapperCls--04120";
export var grandForeImgWrapperCls = "FeatureBanner-module--grandForeImgWrapperCls--a5928";
export var subTitleCls = "FeatureBanner-module--subTitleCls--8688a";
export var textGrey100 = "FeatureBanner-module--text-grey-100--ef40b";
export var textGrey150 = "FeatureBanner-module--text-grey-150--77b6e";
export var textGrey200 = "FeatureBanner-module--text-grey-200--c791d";
export var textGrey300 = "FeatureBanner-module--text-grey-300--127e3";
export var textGrey400 = "FeatureBanner-module--text-grey-400--8e9a1";
export var textGrey500 = "FeatureBanner-module--text-grey-500--1bcb3";
export var textGrey600 = "FeatureBanner-module--text-grey-600--128ed";
export var textGrey700 = "FeatureBanner-module--text-grey-700--17677";
export var textGrey800 = "FeatureBanner-module--text-grey-800--a9924";
export var textGrey900 = "FeatureBanner-module--text-grey-900--aaa66";
export var titleCls = "FeatureBanner-module--titleCls--c817b";